<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <div>***开发中***</div>
            <el-row>
                <el-col style="height: 100%" :span="7">
                    <div class="component-gird">


                        <el-divider content-position="center">基础组件</el-divider>
                        <div class="component-item" v-for="(item,index ) in componentGroup.basic" :key="index">
                            <div class="component-thumb">
                                <img height="64" width="64" :src="item.thumb" alt="">
                            </div>
                            <el-tooltip class="item" effect="dark" :content="item.desc" placement="top-start">
                                <span class="component-name">{{ item.name }} </span>
                            </el-tooltip>
                        </div>


                        <el-divider content-position="center">营销组件</el-divider>
                        <div class="component-item" v-for="(item,index ) in componentGroup.marketing" :key="index">
                            <div class="component-thumb">
                                <img height="64" width="64" :src="item.thumb" alt="">
                            </div>
                            <el-tooltip class="item" effect="dark" :content="item.desc" placement="top-start">
                                <span class="component-name">{{ item.name }} </span>
                            </el-tooltip>
                        </div>


                        <el-divider content-position="center">工具组件</el-divider>
                        <div class="component-item" v-for="(item,index ) in componentGroup.tools" :key="index">
                            <div class="component-thumb">
                                <img height="64" width="64" :src="item.thumb" alt="">
                            </div>
                            <el-tooltip class="item" effect="dark" :content="item.desc" placement="top-start">
                                <span class="component-name">{{ item.name }} </span>
                            </el-tooltip>
                        </div>
                    </div>
                </el-col>





                <el-col style="" class="screen-container" :span="10">
                    <div class="screen-container-inner"></div>
                </el-col>





                <el-col style="height: 100%;text-align:right" :span="7" class="component-option-panel" >
                    <el-row>
                        <el-divider content-position="center">组件属性</el-divider>
                        <div class="option-basic-form" >
                            <el-form :model="componentOptDivForm"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
                                <el-form-item v-for="(item , index) in componentOptDivForm" :label="item.label" :prop="index" :key="index">
                                    <!--       普通输入框        -->
                                    <el-input  v-model="item.value"></el-input>

                                </el-form-item>
                            </el-form>
                        </div>
                    </el-row>
                    <el-row>
                        <el-divider content-position="center">通用样式</el-divider>
                        <div class="option-basic-form" >
                            <el-form :model="componentOptBasicForm" :rules="componentOptBasicRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                                <el-form-item v-for="(item , index) in componentOptBasicForm" :label="item.label" :prop="index" :key="index">
                                    <!--       普通输入框        -->
                                    <div v-if="item.type==='input'">
                                        <el-input v-if="item.type==='input'" v-model="item.value"></el-input>
                                    </div>
                                    <!--       下拉选择        -->
                                    <div v-else-if="item.type==='selector'">
                                        <p v-for="(optItem ,optIdx) in item.options">{{optItem}}</p>
                                        <el-select  v-model="item.value" >
                                            <el-option
                                                v-for="(optItem ,optIdx) in item.options"
                                                :key="optIdx"
                                                :label="optItem.label"
                                                :value="optItem.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <!--       颜色选择器        -->
                                    <div class="colors-selector-row" v-else-if="item.type==='colorSelector'">
                                        <div class="color-selector-icon">
                                            <el-color-picker size="medium" v-model="item.value">
                                                <i class="el-icon-edit"></i>
                                            </el-color-picker>
                                        </div>
                                    </div>
                                </el-form-item>


                            </el-form>
                        </div>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Url from '@/components/Url';
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";

export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Url,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL: IMAGE_UPLOAD_PRO_URL,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            menuList: ['店铺', '首页编辑器'],
            showColorSelectorDialog:false,

            componentGroup: {
                basic: [
                    {
                        name: "搜 索 栏",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "幻 灯 片",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "导 航 组",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "通 知 条",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "商品分类",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                ],
                marketing: [
                    {
                        name: "品牌专场",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "热销商品",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "开屏广告",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "优 惠 券",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "多人拼团",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "秒杀专场",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "直播间",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },

                ],
                tools: [
                    {
                        name: "在线客服",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "标 题",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "辅 助 线",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "辅助空白",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "组件描述",
                    },
                    {
                        name: "悬浮按钮",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "通常用于 返回顶部，购车，联系客服，刷新页面",
                    },
                    {
                        name: "关注",
                        thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/4tp3G56f19c9085129709ee14d013be869df69b.png",
                        enable: false,
                        desc: "关注公众号",
                    },
                ],
            },
            //基础样式的属性
            componentOptBasicForm: {
                marginTop: {type: "input", value: 0, label: "外边距-上"},
                marginLeft: {type: "input", value: 0, label: "外边距-左"},
                marginRight: {type: "input", value: 0, label: "外边距-右"},
                marginBottom: {type: "input", value: 0, label: "外边距-底"},

                paddingTop: {type: "input", value: 0, label: "内边距-上"},
                paddingLeft: {type: "input", value: 0, label: "内边距-左"},
                paddingRight: {type: "input", value: 0, label: "内边距-右"},
                paddingBottom: {type: "input", value: 0, label: "内边距-底"},



                borderRadius: {type: "input", value: 2, label: "边框圆角大小"},
                borderWidth: {type: "input", value: 1, label: "边框线大小"},
                borderColor: {type: "colorSelector", value: "#000000", label: "边框颜色"},
                backgroundColor: {type: "colorSelector", value: "#ffffff", label: "背景色"},
                color: {type: "colorSelector", value: "#000000", label: "文本颜色"},

                horizontalAlign: {type: "selector", value: "center", label: "水平对齐",
                    option:[
                        {
                            label:"<-居左",
                            value:"center",
                        },
                        {
                            label:"【居中】",
                            value:"center",
                        },
                        {
                            label:"居右->",
                            value:"center",
                        },
                    ]
                },
            },
            //基础样式的属性的校验规则
            componentOptBasicRules:{

            },

            //组件自定义的属性
            componentOptDivForm: {
                id: {type: "input", value: 0, label: "外边距-上"},
            },
            tplObject:{
                PageTitle: {
                    widgetKey:"PageTitle",
                    fixed:true,
                    index:0,
                    basicProps:{

                    },
                    customProps:{
                        leftIcon:null,
                        text:"首页"
                    }
                },
                widgets:[
                    {
                        widgetKey:"Swiper",
                        fixed:true,
                        index:0,
                        basicProps:{

                        },
                        customProps:{
                            leftIcon:null,
                            text:"首页"
                        }
                    },
                ]

            }

        }
    },
    created() {

    },

    //图片上传
    methods: {
        showColorSelector(){
            this.showColorSelectorDialog=!this.showColorSelectorDialog
        }
    }
};
</script>

<style scoped lang="scss">

.container {
    .component-gird {
        border: #2d8cf0 1px solid;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        justify-items: flex-start;
        flex-wrap: wrap;
        text-align: center;
        .component-item {
            box-shadow: 2px 2px 2px lightgrey;
            background-color: #fff;
            margin: 10px;
            padding: 10px;
            border-radius: 5px;
            border: #00b7ff 1px solid;
        }
        .component-name {
            font-size: 14px;
            font-weight: bold;
        }
        .component-desc{
            color: red;
        }
        .component-thumb:hover{
            transform: scale(1.2); /* 鼠标放到图片上的时候图片按比例放大1.5倍   */
        }
    }





    .screen-container{
        display: flex;
        justify-content: space-around;
        height: 100% ;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        .screen-container-inner{
            border: 8px solid lightslategrey;
            border-bottom: 20px solid lightslategrey;
            height: 750px;
            width: 340px;
            border-radius: 10px;
        }
    }



    .component-option-panel{
        border: #2d8cf0 1px solid;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        justify-items: flex-start;
        flex-wrap: wrap;
        text-align: center;
        .option-basic-form{
            .colors-selector-row{
                vertical-align: center;
                .color-selector-value{
                    height: 20px;
                    display: inline-block;
                    font-size: 14px;
                    box-shadow: 2px 2px 2px lightgrey;
                    font-weight: bold;
                    padding: 5px;
                    border-radius: 10px;
                }
                .color-selector-show{
                    display: inline-block;
                    height: 64px;
                    width: 64px;
                    box-sizing: border-box;
                    box-shadow: 2px 2px 2px lightgrey;
                    margin-left: 5px;
                    border-radius: 10px;
                }
                .color-selector-icon{
                    margin-left: 10px;
                    display: inline-block;
                }
            }
        }
    }
}



</style>
